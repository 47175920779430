import React from 'react';
import CATEGORIES from '../constants/categories';
import Page from '../components/scenes/Page/Page/Page';
import { graphql } from 'gatsby';
import DocumentList from '../components/scenes/DocumentList/DocumentList';


export default function Documents( { data } ) {

  return (
    <Page heading="Documents">
      { CATEGORIES.map( ( { category, label }, index ) => {
        const edges = data.allMdx.edges.filter( edge => edge.node.fields.category === category );

        return (
          <section key={ category }>
            <h2 style={ { marginTop: index === 0 ? null : '4rem', marginBottom: '2rem' } }>{ label }</h2>
            <DocumentList docs={ edges } />
          </section>
        );
      } ) }
    </Page>
  );
}

export const query = graphql`
  query query {
    allMdx(sort: {fields: frontmatter___order, order: ASC}) {
      edges {
        node {
          fields {
            category
            slug
          }
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;